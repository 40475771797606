import React, { useState, useEffect } from 'react';
import useAssessment from '../../../../hooks/assessment';
import Button from '../../../Button';
import LoadingSpinner from '../../../../utils/LoadingSpinner';

const EmployeeCode = props => {
  const { askForLastName } = props;

  const { nextStep, preInstructions, failureInstructions } = useAssessment();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState();
  const [code, setCode] = useState('');
  const [group, setGroup] = useState(''); // only supplied during testing
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');

  const getQueryParamCaseInsensitive = paramName => {
    const urlParams = new URLSearchParams(window.location.search);

    for (const [key, value] of urlParams.entries()) {
      if (key.toLowerCase() === paramName.toLowerCase()) {
        return value;
      }
    }

    return;
  };

  useEffect(() => {
    const codeParam = getQueryParamCaseInsensitive('code');
    const emailParam = getQueryParamCaseInsensitive('email');
    const lastNameParam = getQueryParamCaseInsensitive('lastname');
    const groupParam = getQueryParamCaseInsensitive('group');

    if (codeParam) {
      setCode(decodeURIComponent(codeParam));
    }
    if (emailParam) {
      setEmail(decodeURIComponent(emailParam));
    }
    if (lastNameParam) {
      setLastName(decodeURIComponent(lastNameParam));
    }
    if (groupParam) {
      setGroup(decodeURIComponent(groupParam));
    }
    const url = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, url);
  }, []);

  const submitData = () => {
    if (!code || code.length === 0) {
      setError('Your unique code is required.');
    } else if (!askForLastName && (!email || email.trim().length === 0)) {
      setError('A valid email is required.');
    } else if (askForLastName && (!lastName || lastName.trim().length === 0)) {
      setError('Your last name is required.');
    } else {
      setSubmitted(true);
      nextStep({ employeeCode: code, email, lastName, group });
    }
  };

  /*const onEnter = event => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      submitData();
    }
  };

  document.addEventListener('keydown', onEnter);*/

  return (
    <div className="mt-3 flex flex-col w-full justify-center items-center">
      {submitted ? (
        <LoadingSpinner showLogo={false} />
      ) : (
        <>
          {preInstructions ? (
            <div className="my-2 border-l-4 border-yellow-400 bg-yellow-50 p-4">
              <div className="items-center flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-yellow-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path
                      fill-rule="evenodd"
                      d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>

                <div className="ml-3">
                  <p className="text-lg text-yellow-700">{preInstructions}</p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="mb-4 mt-12 text-center">
            {askForLastName
              ? failureInstructions
                ? failureInstructions
                : `Please enter your employee code and last name.`
              : `Please enter your employee code and your email address. This may be your personal or work email.`}
          </div>
          <input
            type="text"
            defaultValue={code}
            onChange={e => {
              setError();
              setCode(e.target.value);
            }}
            name="code"
            id="code"
            style={{ width: 240 }}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
            placeholder="Your Employee Code"
          />
          {askForLastName ? (
            <input
              type="text"
              defaultValue={lastName}
              onChange={e => {
                setError();
                setLastName(e.target.value);
              }}
              name="name"
              id="name"
              style={{ width: 240 }}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-md border-gray-300 rounded-md"
              placeholder="Your Last Name"
            />
          ) : (
            <input
              type="email"
              defaultValue={email}
              onChange={e => {
                setError();
                setEmail(e.target.value);
              }}
              name="email"
              id="email"
              style={{ width: 240 }}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-md border-gray-300 rounded-md"
              placeholder="Your Email Address"
            />
          )}

          <div className="mt-2 text-red">{error}</div>
          <Button onClick={submitData} text="CONTINUE" />
        </>
      )}
    </div>
  );
};

export default EmployeeCode;
